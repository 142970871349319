.instructions-container {
    display: flex;
    padding-top: 30px;

    .instructions {
        width: 100%;
        line-height: 2;

        .create-db-img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            cursor: zoom-in;
        }
    }
}

.copy-row {
    height: 50px;
    display: flex;
    align-items: center;

    .copy-btn {
        cursor: pointer;
        margin-left: 8px;
        margin-bottom: 6px;
    }
}

.img-zoom {
    cursor: zoom-out;
}