.accounts-container {
  padding: 24px 24px 12px 24px;

  .accounts-header {
    display: flex;
    justify-content: space-between;

    .account-info {
      display: flex;
      justify-content: space-around;
      gap: 70px;
      width: 100%;
      padding-left: 20px;
      padding-right: 30px;
    }
  }

  .account-row {
    padding: 8px 0 8px 14px;
    display: flex;

    .ant-checkbox {
      margin-right: 20px;
    }

    .text-inputs {
      display: flex;
      justify-content: space-between;
      width: 100%;
      gap: 50px;
      padding-left: 20px;

      input {
        background: none;
        border: 1px solid #878C94;
        line-height: 22px;
        color: #FFFFFF;
        width: 100%;
      }
    }

    .delete {
      padding-left: 10px;
      display: flex;
      align-items: center;
      cursor: pointer;
      width: 30px;

      &.hidden {
        cursor: default;
      }
    }
  }

  .add-account-btn {
    padding-top: 30px;
  }
}
