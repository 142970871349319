.apn-rat-type-use-case {

  h2 {
    font-family: ATTAleckSansMd;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .table {
    .table-header {
      display: flex;
      padding: 0 20px;
      background: #31353D;
      height: 31px;
      align-items: center;
      justify-content: space-between;

      span {
        font-family: ATTAleckSansMd;
        font-size: 12px;
        line-height: 24px;

        &:nth-child(1), &:nth-child(3) {
          flex-basis: 35%;
        }

        &:nth-child(2) {
          flex-basis: 30%;
        }
      }
    }

    .table-content {
      background: #171B1F;
      border-radius: 0 0 14px 14px;

      .table-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom:  1px solid #31353D;
        padding: 8px 20px;

        &:last-child {
          border-bottom: none;
        }

        span {
          line-height: 24px;
          overflow-wrap: break-word;
          word-break: break-word;
          hyphens: auto;
          font-family: ATTAleckSansMd;
          font-size: 14px;

          &:nth-child(1), &:nth-child(3) {
            flex-basis: 35%;
          }

          &:nth-child(2) {
            flex-basis: 30%;
          }
        }

        .link {
          display: flex;
          gap: 3px;
          cursor: pointer;
          flex-direction: column;
          flex: 1;

          .use-case {
            display: flex;
            align-items: center;
            gap: 5px;
            font-family: ATTAleckSansMd;
            font-size: 14px;
            line-height: 24px;
            color: #009FDB;
          }
        }
      }
    }
  }
}