.apns-table-row {
  color: white;
  display: flex;
  justify-content: space-between;

  .apns-table-column {
    font-family: ATTAleckSansBd;
    padding: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .apns-table-cell {
    padding: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .delete {
    cursor: pointer;
  }
}

.apns-buttons {
  display: flex;
  gap: 50px;
  margin-top: 20px;
}