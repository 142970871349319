.customers-container {
  background: #212324;
  border-radius: 12px;
  margin-top: 16px;
  width: 100%;
  padding: 24px 40px;

  .customers-header {
    align-items: center;
    display: flex;
    gap: 16px;
    justify-content: space-between;
    margin-bottom: 32px;

    .header-title {
      align-self: center;
      color: #f8fafb;
      display: flex;
      font-family: ATTAleckSansBd;
      font-size: 26px;
      gap: 24px;
      line-height: 28px;
    }
  }

  .table-container {
    border: 1px solid #FFFFFF;
    border-radius: 2px;
    background: #181b1f;

    .table-row {
      color: white;
      display: flex;
      justify-content: space-between;

      .table-column {
        font-family: ATTAleckSansBd;
        text-align: center;
        border-bottom: 1px solid #FFFFFF;
        background: gray;
        padding: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        color: black;
      }

      .table-cell {
        border-bottom: 1px solid #FFFFFF;
        padding: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        .apn-item {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        a {
          color: rgb(36, 149, 246);
          text-decoration: none;
        }

        .draft {
          color: rgb(36, 149, 246);
          cursor: pointer;
        }

        .pencil-icon {
          cursor: pointer;
        }
      }

      .info-icon {
        margin-left: 5px;
      }
    }
  }
}

.step {
  display: flex;
  justify-content: space-between;
}