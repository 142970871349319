.customer-details-container {
  padding: 24px 24px 12px 24px;

  .row {
    display: flex;
    padding: 8px 0;
    height: 32px;

    input {
      background: none;
      border: 1px solid #878C94;
      line-height: 22px;
      color: #FFFFFF;
      padding-left: 8px;

      &.large {
        width: calc(100% - 162px);
      }

      &.medium {
        width: calc(50% - 170px);
      }

      &.disabled {
        background: rgb(68 69 72);
        color: rgb(113 114 116);
        pointer-events: none;
      }
    }

    .label {
      display: flex;
      align-items: center;
      width: 150px;

      &:not(:first-child) {
        justify-content: end;
        padding-right: 25px;
      }
    }

    .select-wrapper {
      border: 1px solid #878C94;
      width: calc(50% - 170px);
      height: 32px;
    }

    .ant-select-selector {
      background: transparent;
      color: #FFFFFF;
      border: 0;
      border-radius: 0;

      &.ant-select-focused {
        border-color: #FFFFFF;
      }

      .ant-select-selection-item {
        background: #494C4D;

        .ant-select-selection-item-remove {
          color: #FFFFFF;
        }
      }
    }

    .ant-select-arrow {
      color: #878C94;
    }

    .ant-select-clear {
      color: #878C94;
    }

    .ant-radio-group {
      margin: 4px 0;
    }
  }

  .distribution-container {
    padding-left: 150px;
    padding-right: 7px;

    .distribution-labels {
      margin-top: 20px;
      display: flex;
      justify-content: space-around;
      gap: 175px;
    }

    .distribution-lists {
      display: flex;
      justify-content: space-between;
      height: auto;

      .distribution-list {
        width: calc(50% - 89px);

        textarea {
          background: transparent;
          color: #FFFFFF;
          border: 1px solid #878C94;
          border-radius: 0;
        }
      }
    }
  }
}