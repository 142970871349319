.collapsible-filterable-list {
  .list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .list-title {
      font-family: ATTAleckSansMd;
      font-size: 16px;
      display: flex;
      align-items: center;
      gap: 8px;

      span {
        font-family: ATTAleckSansMd;
        font-size: 12px;
        background: #1D2329;
        border-radius: 16px;
        padding: 1px 6px;
      }
    }

    .list-info {
      display: flex;
      gap: 8px;
      align-items: center;

      span {
        font-family: ATTAleckSansMd;
        font-size: 14px;
      }

      button {
        background: none;
        border: none;
        cursor: pointer;

        svg {
          width: 24px;
          height: 24px;
          fill: #F8FAFB;
          transition: transform 0.5s ease;
        }
      }
    }
  }

  .list-wrapper {
    transition: transform 0.5s ease, opacity 0.5s ease;
    transform-origin: top;

    &.collapsed {
      transform: translateY(50%);
      opacity: 0;
      height: 0;
      overflow: hidden;
    }

    &.expanded {
      transform: translateY(0);
      opacity: 1;
      height: auto;
    }

    .search-box {
      position: relative;
      margin: 20px 0;

      input {
        width: calc(100% - 66px);
        padding: 12px 16px 12px 48px;
        border: 1px solid white;
        border-radius: 24px;
        background: transparent;
        outline: none;
        color: #F8FAFB;
        font-size: 16px;

        &::placeholder {
          color: #F8FAFB;
        }
      }

      .search-icon {
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        fill: #F8FAFB;
      }
    }

    .item-list {
      display: flex;
      flex-direction: column;

      .item-list-title {
        display: grid;
        grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr) minmax(50px, auto);
        padding-left: 20px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background: #31353D;
        height: 31px;
        align-items: center;

        span {
          font-family: ATTAleckSansMd;
          font-size: 12px;
        }
      }

      .item {
        display: grid;
        grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr) minmax(50px, auto);
        height: 40px;
        border-bottom: 1px solid #31353D;

        span {
          padding: 0 10px;
          height: 40px;
          font-size: 14px;
          font-family: ATTAleckSansMd;
          display: flex;
          align-items: center;

          &.na {
            opacity: 0.4;
          }
        }
      }
    }
  }
}
