.table-issue {

  .table-issue-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    .table-issue-category {
      display: flex;
      align-items: center;
      gap: 8px;
      color: white;

      .category-name {
        font-family: ATTAleckSansMd;
        font-size: 16px;
      }

      .icon {
        display: flex;
        align-items: center;
      }

      .total-issues {
        border-radius: 16px;
        padding: 0 6px;
        height: 15px;
        font-size: 12px;
        font-family: ATTAleckSansMd;
        display: flex;
        align-items: center;
        text-align: center;
      }
    }
  }

  .empty-state {
    height: 120px;
    background: #31353D;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    flex-direction: column;
    text-align: center;
    padding: 0 24px;

    .empty-state-title {
      font-size: 16px;
      font-family: ATTAleckSansMd;
      margin-bottom: 4px;
    }

    .empty-state-desc {
      font-size: 14px;
    }
  }

  .table {
    display: flex;
    flex-direction: column;

    .table-header {
      display: flex;
      background: #31353D;
      border-radius: 10px 10px 0 0;
      height: 31px;
      align-items: center;
      padding: 0 10px;
      justify-content: space-between;

      .header-cell {
        color: white;
        font-family: ATTAleckSansMd;
        font-size: 12px;
        letter-spacing: -0.03em;
      }
    }

    .table-row {
      height: 40px;
      padding: 0 10px;
      border-bottom: 1px solid #31353D;
      display: flex;
      align-items: center;
      color: white;
      justify-content: space-between;

      &:hover {
        cursor: pointer;
      }

      &:last-child {
        border-bottom: none;
      }

      .cell {
        font-size: 14px;
        display: flex;
        align-items: center;

        &.importance-wrapper {
          width: 77px;

          .importance {
            font-size: 12px;
            font-family: ATTAleckSansBd;
            padding: 4px 10px;
            border: 1px solid;
            border-radius: 12px;
            text-transform: capitalize;
            width: fit-content;
          }
        }

        &.apn-number{
          justify-content: center;
        }

        &.devices, &.destinations {
          justify-content: center;
          gap: 8px;
        }
      }
    }
  }

  @mixin use-case-column {
    flex-grow: 1;
  }

  @mixin device-column {
    width: 120px;
    flex-shrink: 0;
    text-align: center;
  }

  @mixin apn-column {
    width: 80px;
    flex-shrink: 0;
    text-align: center;
  }

  @mixin importance-column {
    width: 80px;
    flex-shrink: 0;
    text-align: center;
  }

  .table-header .header-cell:nth-child(1),
  .table-row .cell:nth-child(1) {
    @include use-case-column;
  }

  .table-header .header-cell:nth-child(2),
  .table-row .cell:nth-child(2) {
    @include importance-column;
  }

  .table-header .header-cell:nth-child(3),
  .table-row .cell:nth-child(3),
  .table-header .header-cell:nth-child(4),
  .table-row .cell:nth-child(4) {
    @include device-column;
  }

  .table-header .header-cell:nth-child(5),
  .table-row .cell:nth-child(5) {
    @include apn-column;
  }
}
