.navbar-wrapper {
  .navbar {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .nav-item {
      color: #F8FAFB;
      font-family: ATTAleckSans;
      font-size: 16px;
      cursor: pointer;
      line-height: 32px;
      padding: 0 16px;
      text-decoration: none;
    }
  }
}
