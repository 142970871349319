.details-container {
  background: #212324;
  border-radius: 12px;
  margin-top: 16px;
  width: 100%;
  padding: 24px 40px;

  .details-header {
    align-items: center;
    display: flex;
    gap: 16px;
    justify-content: space-between;
    margin-bottom: 32px;

    .header-title {
      align-self: center;
      color: #f8fafb;
      display: flex;
      font-family: ATTAleckSansBd;
      font-size: 26px;
      gap: 24px;
      line-height: 28px;
    }

    .header-buttons {
      align-items: center;
      display: flex;
      gap: 24px;
    }
  }

  .ant-collapse-header-text {
    color: white;
    font-size: 18px;
    font-family: ATTAleckSansBd;
  }

  .ant-collapse-header {
    display: flex !important;
    flex-direction: row-reverse !important;
    padding: 18px !important;
  }

  .ant-collapse-item {
    border-bottom: 1px solid #3f4449;
  }

  .ant-collapse-content-box {
    color: #FFFFFF;
    background-color: rgba(49, 53, 61, .502);
  }
}