@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

.security-issue-details {
  position: absolute;
  top: 0;
  right: 0;
  width: 470px;
  height: calc(100% - 68px);
  background-color: black;
  box-shadow: 0 12px 48px 12px black;
  z-index: 1000;
  overflow-y: auto;
  padding: 34px 23px;
  animation: slideIn 0.5s ease-in-out;
  color: white;

  &.closing {
    animation: slideOut 0.5s ease-in-out;
  }

  .security-issue-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .security-issue-details-title {
      font-size: 14px;
      font-family: ATTAleckSansMd;
    }

    button {
      background: none;
      border: none;
      cursor: pointer;
    }
  }

  .details {

    .issue-details-title {
      font-size: 22px;
    }

    .issue-details-time {
      color: #BDC2C7;
      font-size: 12px;
    }

    .issue-details-desc {
      font-size: 18px;
    }

    .issue-details-widgets {
      display: flex;
      justify-content: space-between;
      margin: 24px 0;
      gap: 16px;

      .widget {
        background: #1D2329;
        border-radius: 10px;
        flex-basis: 50%;
        height: 200px;
      }
    }

    svg.expanded {
      transform: rotate(0deg);
      transition: transform 0.5s ease;
    }

    svg.collapsed {
      transform: rotate(180deg);
      transition: transform 0.5s ease;
    }

    .affected-apns-list,
    .device-list-wrapper {
      transition: transform 0.5s ease, opacity 0.5s ease;
      transform-origin: top;
    }

    .affected-apns-list.collapsed,
    .device-list-wrapper.collapsed {
      transform: translateY(50%);
      opacity: 0;
      height: 0;
      overflow: hidden;
    }

    .affected-apns-list.expanded,
    .device-list-wrapper.expanded {
      transform: translateY(0);
      opacity: 1;
      height: auto;
    }

    .issue-details-recommended-action {
      margin: 20px 0;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .issue-details-recommended-title {
        color: #BDC2C7;
        font-size: 14px;
        font-family: ATTAleckSansMd;
      }

      .recommended-action-item {
        background: #1D2329;
        border-radius: 10px;
        padding-left: 30px;
      }
    }

    .issue-details-affected-apns {
      padding: 16px 0;
      border-top: 1px solid #1D2329;
      border-bottom: 1px solid #1D2329;
      display: flex;
      flex-direction: column;

      .issue-details-affected-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
          background: none;
          border: none;
          cursor: pointer;

          svg {
            width: 24px;
            height: 24px;
            fill: #F8FAFB;
          }
        }
      }

      .affected-apns-list {
        margin-top: 18px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        div {
          padding: 0 10px;
          background: #3C2121;
          color: #FF605D;
          font-family: ATTAleckSansMd;
          font-size: 14px;
          height: 34px;
          display: flex;
          align-items: center;
          border-radius: 9px;
        }
      }
    }

    .device-section, .asset-section {
      margin-top: 16px;
    }

    .section-header-title {
      font-family: ATTAleckSansMd;
      font-size: 16px;
      display: flex;
      align-items: center;
      gap: 8px;

      span {
        font-family: ATTAleckSansMd;
        font-size: 12px;
        background: #1D2329;
        border-radius: 16px;
        padding: 1px 6px;
      }
    }
  }
}
